@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../SiteHeader/variables';

.proceedThroughCheckout {
  button {
    height: $header-button-height;
    width: 100%;

    @include media-breakpoint-down('sm') {
      font-size: 18px;
    }

    @include media-breakpoint-up('md') {
      width: $header-button-width-md;
      font-size: 18px;
      padding: 0;
    }

    @include media-breakpoint-up('xl') {
      width: $header-button-width-xl;
    }
  }
}
