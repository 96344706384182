@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

$title-height-mobile: 50px;
$title-checkout-btn-height-mobile: 110px;

.loading {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $colour-primary-grey--alpha-40;
  display: flex;
  align-items: center;
  z-index: 9999;
}

.pinBar {
  // stylelint-disable-next-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals
  background: rgba($colour-primary-grey, 0.95);
  box-shadow: none;
  font: {
    family: Waitrose;
    size: 16px;
  }
  left: 0;
  position: fixed;
  top: 100%;
  transition: transform 500ms;
  width: 100%;
  z-index: $zindex-pinbar;

  @include media-breakpoint-up('md') {
    bottom: 0;
    padding: 16px 32px;
    top: auto;
  }
}

.open {
  @include media-breakpoint-down('sm') {
    transform: translateY(-100%);
  }
}

.closed {
  @include media-breakpoint-down('sm') {
    transform: translateY(-$title-checkout-btn-height-mobile);
  }
}

.title {
  background: $colour-transparent;
  border: 0;
  padding: 0;
  touch-action: manipulation;
  user-select: none;
  height: $title-height-mobile;
  line-height: $title-height-mobile;
  text-align: center;
  width: 100%;

  &,
  &:hover,
  &:focus,
  &:active {
    color: $colour-waitrose-green;
  }

  &::after {
    content: '\e911';
    display: inline-block;
    font: {
      family: 'WaitroseGlyph';
      size: 20px;
    }
    position: relative;
    transform: rotate(270deg);
    transition: transform 500ms;
    vertical-align: bottom;

    .open & {
      transform: rotate(90deg);
    }
  }

  @include media-breakpoint-up('md') {
    display: none;
  }
}