@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../wdx/mixins/typography';
@import '../SiteHeader/variables';

%condition-message {
  font: {
    size: 16px;
    weight: 200;
  }
  padding: 10px 13px 0 50px;
  position: relative;
}

%condition-message-icon {
  content: '\e924';
  font-family: WaitroseGlyph;
  position: absolute;
  left: 0;
  top: -3px;
}

.checkout {
  p {
    @extend %condition-message;
    margin: 20px 0 10px;

    &::before {
      @extend %condition-message-icon;
      display: block;
      font-size: 54px;

      @include media-breakpoint-down('sm') {
        left: .4em;
        top: .2em;
        font-size: 2em;
      }
    }

    strong {
      font-weight: 400;
    }
  }

  &.fullSize {
    width: 100%
  }
}

.disabledTooltip {
  max-width: 200px;

  // Makes the background and text colours look like the old one, just to keep the colours different from the amend bar
  &.light {
    // stylelint-disable-next-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals
    background-color: #fbf5e4;
    // stylelint-disable-next-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals
    color: #54565a;
  }
}

.buttonOverlayTrigger {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.inlineMsg {
  margin-top: 16px;

  div:nth-of-type(2) {
      margin-top: 16px;
  }
}